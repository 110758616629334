import Dompurify, { addHook } from 'dompurify';
import { marked } from 'marked';

addHook('afterSanitizeAttributes', function (node) {
	if (node.tagName === 'A') {
		node.setAttribute('target', '_blank');
		node.setAttribute('rel', 'noopener noreferrer');
	}
});

export function processFormattedText (text: string) {
	return Dompurify.sanitize(marked(text));
}
